@import './variables.scss';

@import url('https://use.typekit.net/dlf4sez.css');
@import './font.scss';
@import './colors.scss';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.font {
  &_hairline {
    font-weight: 100;
  }

  &--italic {
    font-style: italic;
  }
}

:fullscreen .hide-on-fullscreen,
:fullscreen ~ .hide-on-fullscreen,
:fullscreen + .hide-on-fullscreen {
  display: none;
}
