.find-out-more-content td {
  border: 1pt solid #003d7a;
  padding: 15px;
}

.find-out-more-content .row_label {
  vertical-align: top;
  background-color: #003d7a;
  width: 123pt;
  color: #ffffff;
  border-bottom: solid 1px #fff;
}

.find-out-more-content .col_label {
  vertical-align: top;
  background-color: #003d7a;
  width: 194.2pt;
  color: #ffffff;
  text-align: center;
  border-right: solid 1px #fff;
}
