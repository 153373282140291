@import './variables.scss';

.color {
  &--primary {
    color: var(--gentian-primary) !important;
  }

  &--secondary {
    color: var(--gentian-secondary) !important;
  }

  &--tertiary {
    color: var(--gentian-tertiary) !important;
  }
}

.bg {
  &--primary {
    background-color: var(--gentian-primary) !important;
  }

  &--secondary {
    background-color: var(--gentian-secondary) !important;
  }

  &--tertiary {
    background-color: var(--gentian-tertiary) !important;
  }
}
