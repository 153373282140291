:root {
  --header-height: 64px;
  --footer-height: 32px;
  --content-spacing: 32px;
  --content-spacing-narrow: 16px;
  --gentian-primary: #003d7a;
  --gentian-secondary: #0a70c4;
  --gentian-tertiary: #b9d38d;
  --gentian-light-gray: #f2f2f2;
  --gentian-dark-gray: #363636;
}
