body {
  font-family: 'lato', sans-serif;
}

.font {
  &--italic {
    font-style: italic;
  }

  &__hairline {
    font-weight: 100;
  }

  &__thin {
    font-weight: 200;
  }

  &__light {
    font-weight: 300;
  }

  &__regular {
    font-weight: 400;
  }

  &__medium {
    font-weight: 500;
  }

  &__semibold {
    font-weight: 600;
  }

  &__bold {
    font-weight: 700;
  }

  &__heavy {
    font-weight: 800;
  }

  &__black {
    font-weight: 900;
  }
}
